body{
    touch-action: pan-x pan-y;
    overflow: hidden;
}

//GLOBAL

.content,
.dashboard-content{


    .brandBlue{
        background-color: #004EE0!important;
    }
    
    .brandYellow{
        background-color: #FFB700!important;
    }
    
    .cntr{
        text-align: center;
    }
    
    .cbk{
        color: black;
    }
    
    .lnk{
        text-decoration: underline;
    }
    
    .mtop1{
        margin-top: 1rem;
    }

    .mtop05{
        margin-top: 0.6rem;
    }

    .mtop0{
        margin-top: 0rem;
    }
    
    .mtb0{
        margin-bottom: 0px;
    }
    
    .mtb1{
        margin-bottom: 1rem;
    }
    
    .ptop0{
        padding-top: 0rem !important;
    }
    
    .ptop1{
        padding-top: 1rem;
    }
    
    .field{
        margin-top: 1rem;
    }

    .stepper-ui-icons{
        padding: 0 !important;
        margin: 1.7rem 2rem 0.5rem;
        font-size: 11px;
        line-height: 1;
        text-align: left;

        .icon-grid{
            text-align: right;
        }

        .column{
            padding: 0 !important;
            i{
                font-size: 35px;
            }
        }
    }

    .stepper-ui-steplbl{
        font-size: 12px;
        line-height: 1;
    }
    
    .p0{
        padding: 0;
    }

    .m1{
        margin: 1rem;
    }

    .m02{
        margin: 0.2rem;
    }

    .dvd{
        margin: 1rem 0rem 0rem;
    }

    .accord-title{
        margin-bottom: 1rem;
    }

    .title-accord-label{
        display: flex;

        i{
            color: black;
            margin: 0;
            margin-top: 0.2rem!important;
        }

        .content{
            color: black;
        }
    }
}

// LOGIN
.content{
    input{
        text-align: center!important;
    }

    .lft input{
        text-align: left!important;
    }
}

.ui.dimmer{
    background-color: white!important;
}

.fixed-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    .d-header{
        background-color: white;
        border-bottom: 0.5rem solid #2185D0;
        display: flex;
        
        .hamburger{
            margin-top: 1.5rem;
            margin-left: 1rem;
            font-size: 20px;
            color: #2185D0;
        }

        .call{
            margin-top: 1.5rem;
            margin-right: 1rem;
            font-size: 20px;
            color: #2185D0;
            transform: rotate(90deg);
        }

        .image-logo{
            display: flex;
            margin: auto;
            padding: 0.5rem;
            width: 9rem !important;
        }
    }

    .loc{
        background-color: #abc8ff;
        padding: 0.3rem;
        margin-bottom: 1rem;
        display: flex;
        text-align: left;

        .edt{
            margin-left: auto;
        }

        .pt06{
            padding-top: 0.6rem;
        }

        .pt02{
            padding-top: 0.2rem;
        }
    }  
}

.fixed-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0.5rem;
    background-color: white;
}

.fixed-footer.fin{
    background-color: #e8e8e8;
}

.dashboard-sidebar{
    background-color: #08375a !important
}

// DASHBOARD
.dashboard{
    height: 100vh;

    .dashboard-pushable{
        border: none;
    }
    
    .dashboard-content{
        margin-top: 68.44px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #E8E8E8;

        .dim-loader{
            background-color: #0e395a96 !important;
            z-index: 0;
        }

        .stepper{
            background-color: rgb(255 232 165);
        }

        .validation-notice{
            font-size: 9px;
            margin: 2rem 6rem 0rem;
            width: 100%;
        }

        .btn-grp-max{
            height: 4rem;
        }

        .pd-sides{
            padding: 1rem;

            .pl2{
                padding-left: 2rem;
            }

            .t-amount{
                width: 100%;
            }

            .laundry-fee-content{
                margin-bottom: 2rem;
            }

            .ldfc{
                font-size: 12.5px;
                margin-top: 3px;

                .item{
                    font-size: 12.5px;
                    margin-top: 5px;
                }
            }

            .t-left{
                text-align: left;
            }

            .subHed{
                text-align: left;
                font-size: 11px;
            }

            .mb03{
                margin-bottom: 0.3rem;
            }

            .mb0{
                margin-bottom: 0rem;
            }

            .wash-btn-grps{
                display: flex;
            }

            .load-number{
                display: flex;
                margin-bottom: 1rem;
                align-items: center;
                justify-content: center;

                .label-load{
                    margin: 0rem 1rem;
                    font-size: 2rem;
                }
            }

            .soap-number{
                display: flex;
                margin-bottom: 1rem;
                align-items: center;
                justify-content: center;

                .label-load{
                    margin: 0rem 1rem;
                    font-size: 2rem;
                }
            }

            .opts{
                display: flex;
                margin-bottom: 1rem;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .mr3{
                    margin-right: 3rem;
                }

                .mt1{
                    margin-top: 0.5rem;
                }
            }
        }

        .header-profile{
            text-align: left;
            margin-top: 1rem;
            text-decoration: none;

            a{
                font-size: 15x;
                text-decoration: underline;
                color: #004EE0;
            }
        }

        .user-icon-photo{
            margin-top: 1.5rem;
            margin-right: 1rem;
            font-size: 70px;
            color: #FFB700;
        }

        .s-lbl{
            .s-badges{
                margin-top: 1rem;
            }
        }

        .p51{
            padding: 0.1rem 1rem;

            .tot{
                border-top: 1px dotted black;
                padding-top: 1rem;
            }
        }

        .pef{
            margin-left: auto;
            text-align: right;
        }

        .img-confirm{
            display: flex;
            justify-content: center;
        }
    }
}

// PICK UP DELIVERY 
.pick-delivery-opts{
    padding: 1rem;
    margin: 1rem;

    .ui.header{
        margin: 0.5rem 0rem;
    }

    .get-exact-btn{
        position: absolute;
        z-index: 100;
        left: 1.5rem;
        top: 4rem;
    }
}

.transactions{
    height: 100vh;
    margin: 68.44px 1.5rem;
    text-align: left;

    .box-bg{
        background-color: #ffc140;
        height: 10rem;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: -1;
    }

    .active-order{
        .spf {
            text-align: center;

            .stepper-ui-icons{
                padding: 0 !important;
                margin: 2rem 2rem 0.5rem;
                line-height: 1;
                text-align: center;

                .txt-step{
                    font-size: 11px;
                }
            }
        }
    }

    .ui.header{
        padding-top: 1rem;
    }
}

// SITE
.site{
    background-color: #4182CA;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .margin-site{
        width: 28rem;
        margin: 2rem auto;
        height: 100vh;
        
        .content{
        color: white;
        }

        img{
            margin: auto;
        }

        .column{
            .price{
                background-color: transparent!important;
            }
        }

        .pricing h2{
            text-align: left;
            color: #e7f8ff !important;


            .content .sub.header{
                color: white !important;
            }
        }

        p{
            color: white;
            text-decoration: underline;
        }
    }
}

.ui.page.modals{
    z-index: 1;
    background-color: #0e395a96 !important;
}

.walk-in{
    height: 100vh;
    margin: 86.44px 1.5rem;
}